import React, { useEffect, useState } from 'react'
import CardItem from './CardItem.js'
import './Cards.css'



function Cards() {
  const [articles,setArticles]=useState(null)

  const getArticles= async () => {
    const url="/backend/article/articles-fetch-top/"
    const response = await fetch(url)
    const jsonresp=await response.json()
    if (response.ok){
   
    console.log("user??")
    console.log(jsonresp)
    setArticles(jsonresp)
  } 
    

  }

  useEffect( ()=>{
  getArticles()
  }
  ,[])

  console.log("30")
  console.log(articles)
  return (
    <div className='cards'>
        <div className="cards__container">
            <div className="cards__wrapper">
                <div className="cards__items">
                  { articles && articles.map( (ob)=> (
                
                    <CardItem src={`images/${ob.title}.jpg`}
                    key={ob.title}
                    text={ob.text}
                    label={ob.label}
                    path='/services' />
                 
                  )   ) }
                     <CardItem src="images/Article.jpg" 
                    text= "What do we do?" 
                    label='Article' 
                    path='/article/1'  />
                     <CardItem src="images/Reports.jpg" 
                    text= "May 2023: Two Sample Reports" 
                    label='Reports' 
                    path='/article/1'  />
                     <CardItem src="images/Update.jpg" 
                    text= "A Preview of Future Features" 
                    label='Update' 
                    path='/article/1'  />
                     <CardItem src="images/Freebie.jpg" 
                    text= "Our Process: A Deep Dive" 
                    label='Freebie' 
                    path='/article/1'  />
                </div>
            </div>
        </div>
        <h3>Make an account to view the sample reports that we've made freely available!</h3>
    </div>
  )
}

export default Cards;
