import {Outlet,Navigate} from 'react-router-dom';
import { auth } from  '../config/firebase';

const PrivateRoutes= () => {
    const userPriveleges=0
    return(
        auth.currentUser? <Outlet context={userPriveleges}/> : <Navigate to='/' />
    )




}

export default PrivateRoutes