import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Button } from './Button';
import './Navbar.css';
import LoginPopup from './LoginPopup';
import RegisterPopup from './RegisterPopup';
import {auth} from '../config/firebase';
//



function DisplayButtons({button,handleLoginAttempt,handleRegisterAttempt}){
return(<>
    {button && <Button buttonStyle='btnv--outline' onClick={handleLoginAttempt}>LOGIN</Button>}
    {button && <Button buttonStyle='btnv--outline'onClick={handleRegisterAttempt}>SIGN UP</Button>}
                    </>
)}



    function DisplayMobileButtons({closeMobileMenuAndLogin,closeMobileMenuAndRegister}) {
        return(
            <>
        <li className='nav-item'>
        <Link className='nav-links-mobile' onClick={closeMobileMenuAndLogin}>
        Login
        </Link>
    </li>
    <li className='nav-item'>
        <Link className='nav-links-mobile' onClick={closeMobileMenuAndRegister}>
        Sign Up
        </Link>
    </li>
    </>
    )
    }
    function DisplayMobileProfile({closeMobileMenu}){
        return(
            <>
        <li className='nav-item'>
        <Link className='nav-links-mobile' to='/userprofile' onClick={closeMobileMenu}>
        Your Profile
        </Link>
    </li>
  
    </>
    )
    }

    function DisplayProfile({username}){
        return(
            <>
         <li className='nav-item'>
        <Link to='/userprofile' className='nav-links-special'>
        {username.email}
        </Link>
                        </li>
  
    </>
    )
    }

    function DisplayMobile({username,closeMobileMenu,closeMobileMenuAndLogin,closeMobileMenuAndRegister}){
        if (username==null){
            return <> <DisplayMobileButtons closeMobileMenuAndLogin={closeMobileMenuAndLogin} closeMobileMenuAndRegister={closeMobileMenuAndRegister}/>
            </>
        } else {
            return <> <DisplayMobileProfile closeMobileMenu={closeMobileMenu}/>  </>
        }
    }

    function Display({username,button,handleLoginAttempt,handleRegisterAttempt,logout}){
        if (username==null){
            return <> <DisplayButtons button={button}
            handleLoginAttempt={handleLoginAttempt}
            handleRegisterAttempt={handleRegisterAttempt} 
            />
            </>
        } else {
            return <> 
            <DisplayProfile username={username}/> 
            {<Button buttonStyle='btnv--outline' onClick={logout}>LOGOUT</Button>}
            </>
        }
    }


    function DisplayContent({username,closeMobileMenu}){
        if (username==null){
            return <></>
        } else {
            return <>   
            <li className='nav-item'>
            <Link to='/content' className='nav-links' onClick={closeMobileMenu}>
            Content
            </Link>
        </li></>
        }
    }

const Navbar =({user}) => {
    const [click,setClick] = useState(false);
    const [loginAttempt,setLoginAttempt] = useState(false);
    const [registerAttempt,setRegisterAttempt] = useState(false);
    const handleBurgerClick = () => setClick(!click);
    const handleLoginAttempt = () => setLoginAttempt(!loginAttempt);
    const handleRegisterAttempt = () => setRegisterAttempt(!registerAttempt);
    const closeMobileMenu= () => setClick(false); 
   
    
 
    const closeMobileMenuAndLogin = () => {setClick(false);
        setLoginAttempt(true);
    }

    const closeMobileMenuAndRegister = () => {setClick(false)
            setRegisterAttempt(true)}

    const [button,setButton] = useState(true);
    const showButton = () => {
        if(window.innerWidth <=960){
            setButton(false);
        } else {
            setButton(true);
        }
    };
  
    const logout= () => {
        auth.signOut()
    }

    window.addEventListener('resize',showButton);

    return (
        <>  
            <LoginPopup state={loginAttempt} stateHandler={setLoginAttempt} />
            <RegisterPopup state={registerAttempt} stateHandler={setRegisterAttempt} />
            <nav className='navbar'>
                <div className='navbar-container'>
                    <Link to="/" className="navbar-logo">
                        EBOOK DATASCI <i className="fa-solid fa-book" />
                    </Link>
                    <div className="menu-icon" onClick={handleBurgerClick}>
                        <i className={click ? "fas fa-times": "fas fa-bars"}/>
                    </div>
                    <ul className={click? 'nav-menu active': 'nav-menu'}>
                        <li className='nav-item'>
                            <Link to='/' className='nav-links' onClick={closeMobileMenu}>
                            Home
                            </Link>
                        </li>
                        <li className='nav-item'>
                            <Link to="https://portfolio.ebookdatasci.com/" className='nav-links' onClick={closeMobileMenu}>
                            About
                            </Link>
                        </li>
                        <li className='nav-item'>
                            <Link to='/pricing' className='nav-links' onClick={closeMobileMenu}>
                            Pricing
                            </Link>
                        </li>
                        <DisplayContent username={user} closeMobileMenu={closeMobileMenu} />
                        <DisplayMobile username={user} closeMobileMenu={closeMobileMenu} closeMobileMenuAndLogin={closeMobileMenuAndLogin} closeMobileMenuAndRegister={closeMobileMenuAndRegister} />
                    
                   <Display username={user} button={button} handleLoginAttempt={handleLoginAttempt} handleRegisterAttempt={handleRegisterAttempt} logout={logout}/>
                   </ul>
                   </div>
            </nav>
           
        </>
    )
}

export default Navbar