import React from 'react';
import Navbar from './components/Navbar';
import Footer from './components/Footer';
import VerifyEmail from './components/VerifyEmail';
import './App.css';
import Home from './pages/Home.js';
import { BrowserRouter as Router,Routes,Route } from 'react-router-dom';

import Pricing from './pages/Pricing';
import SignUp from './pages/SignUp';

import { useState, useEffect } from 'react';
import { AuthProvider } from './components/AuthContext';
import {auth} from './config/firebase';
import { onAuthStateChanged } from 'firebase/auth';
import UserProfile from './pages/UserProfile';
import Content from './pages/Content';
import ArticlePage from './components/ArticlePage';
import NotFound from './pages/NotFound';

import PrivateRoutes from './utils/PrivateRoutes';
import Subscription from './pages/Subscription';
import Tos from './pages/Tos';
import Privacy from './pages/Privacy';
import Blog from './pages/Blog';

function App() {
  
  const [currentUser,setCurrentUser]=useState(null);
  const [timeActive, setTimeActive] = useState(false);

  useEffect(() => {
    onAuthStateChanged(auth, (user) => {
      setCurrentUser(user)
     })
  }, [])
  return (
    
    <>
    {console.log(currentUser)}
    <AuthProvider value={{currentUser,timeActive,setTimeActive}}>
    <Navbar  user={currentUser}/>
    <Routes>
      <Route path='/' element={<Home />}/>
      <Route path='/pricing' element={<Pricing />}/>
      <Route path='/sign-up' element={<SignUp />}/>
      <Route path='/verify-email' element={<VerifyEmail/>} />
      <Route path='/tos' element={<Tos />}/>
      <Route path='/privacy' element={<Privacy />} /> 
      <Route path='/article/:id' element ={<Blog />} />
      
      <Route element={<PrivateRoutes/>} >
      <Route path='/subscription' user={currentUser} element={<Subscription />} />
        <Route path='/userprofile'user={currentUser} element={<UserProfile />} />
        <Route path='/content' element={<Content /> } />
        <Route path='/content/Basic/:id' element={<ArticlePage/>} />
        <Route path={`/content/Premium/:id`} element={<ArticlePage />} />
      </Route>
      
     

      <Route path='*' element = {<NotFound />} />
    </Routes>
    <Footer/>
    </AuthProvider>
   </>
  );
}

export default App;
