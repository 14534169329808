import React, { useEffect } from 'react'
import '../App.css'
import { useState } from 'react';
import { auth } from '../config/firebase';
import { Navigate, useNavigate } from 'react-router-dom';

function UserProfile(user){
  console.log("line 10")
  console.log(user)
  let nav = useNavigate(); 
  const routeChangeSub = () =>{ 
    let path = `/subscription`; 
    nav(path);
  }
    const [userVars,setUserVars]=useState(null)
    const userid = auth?.currentUser?.uid
    const auth_toke = auth?.currentUser?.getIdToken()


    

    useEffect( ()=>
    {   
      const url2="/backend/api/user/user-fetch/" + userid
        const fetchUserVars= async () => {
        const response =await fetch(url2,
        {method: 'GET',
        headers: { 'Content-Type': 'application/json',
          'Authorization':await auth?.currentUser?.getIdToken()
      }}
        )
    
        const user_stuff= await response.json()
        if (response.ok){
        setUserVars(user_stuff)
        console.log("29")
        console.log(response)
        console.log(userVars)
        }
    }
        fetchUserVars()
    }
        ,[])
  const [mailingListPreference, setMailingListPreference] = useState(user.mailingListPreference);

  if(userVars?.subscriptionStatus=="Basic"){
    return (
      <div className="user-profile">
        <h2>{userVars?.email}</h2>
        <p>Subscription Level: {userVars?.subscriptionStatus} <button onClick={routeChangeSub}>Upgrade Subscription Tier</button></p>
        <a href="/content">My Content</a>
      </div>
    );
  }else{
    return (
      <div className="user-profile">
        <h2>{userVars?.email}</h2>
        <p>Subscription Level: {userVars?.subscriptionStatus} <button onClick={Navigate("/")}>Manage Subscription</button></p>
        <a href="/content">My Content</a>
      </div>
    );
  }
  




} export default UserProfile;