import React from 'react'
import '../App.css';
import { Button } from './Button'
import './HeroSection.css';

function HeroSection() {
  return (
    <div className='hero-container'>
        <h1>Need some insight to the eBook market?</h1>
        <p>We can help with that.</p>
        <div className='hero-btnvs'>
            <Button link="/sign-up" className='btnvs' buttonStyle='btnvs--outline' buttonSize='btnv--large'>
            GET STARTED
            </Button>
        </div>
    </div>
  )
}

export default HeroSection