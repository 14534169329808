import React from 'react'
import '../App.css'
import ArticlePage from '../components/ArticlePage'
import ReportsViewer from '../components/ReportsViewer';
import { BrowserRouter as Router,Routes,Route } from 'react-router-dom';
import { Link } from 'react-router-dom';
import './Content.css'
import { auth } from '../config/firebase';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';



export default function Content(props){
    const user = auth?.currentUser?.uid
    console.log(user)
    return (
    <>
    <h1 className="content">Content</h1>
    <Tabs 
     defaultActiveKey="basic"
     id="uncontrolled-tab-example"
     className="mb-3"
    >
        <Tab eventKey="basic" title="Basic">
        <ReportsViewer userid={user} reptype={0} />
        </Tab>
        <Tab eventKey="premium" title="Premium">
        <ReportsViewer  userid={user} reptype={1}/>
        </Tab>
    </Tabs>
  
    </>
    );
}