import React from 'react'
import {Link} from 'react-router-dom'
import './Footer.css'
function Footer() {
  return (
    <div className='footer-container'>
        <section className="footer-subscription">
            <p className="footer-subscription-heading">
                Join our newsletter to receive email updates!
            </p>
            <p className="footer-subscription-text">
            The content on this website is meant to emulate that which might be produced by an actual data science business servicing the eBook market and as such should not be taken as real in and of itself. While the owners and creators of this project have attempted to create a realistic product throughout it, they make no claim to the accuracy or usefulness of anything provided on this site or its associated subdomains. Use at your own risk.
            </p> 
        </section>
        <section className='boilerplate'>
            <Link to="/tos" className='boilerplate-link'>Terms of Service</Link>
            <Link to="/privacy" className='boilerplate-link'>Privacy Policy</Link>
            <Link to="/about" className='boilerplate-link'>Contact Us</Link>
        </section>
       
        </div>
  )
}

export default Footer