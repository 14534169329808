import React from 'react'
import '../App.css'
import './Pricing.css'
import PricingHeader from '../components/PricingHeader'
import PricingCardCont from '../components/PricingCardCont'
import { useState,useEffect } from 'react'
import ToSub from '../components/ToSub'
import { Button } from 'react-bootstrap'
import { Link, Navigate} from 'react-router-dom'
import { auth } from '../config/firebase'



export default function Pricing(){
    
  const [userVars,setUserVars]=useState(null)
  const userid = auth?.currentUser?.uid

  

  useEffect(()=>
  {   
      const url2="/backend/api/user/user-fetch/" + userid
      const fetchUserVars= async () => {
      const response =await fetch(url2,
      {method: 'GET',
      headers: { 'Content-Type': 'application/json',
      'Authorization':await auth?.currentUser?.getIdToken(),}}
      )
  
      const user_stuff= await response.json()
      if (response.ok){
      setUserVars(user_stuff)
      console.log("29")
      console.log(response)
      console.log(userVars)
      }
  }
      fetchUserVars()
  }
      ,[])

      const handleSubscriptionChange = async () => {
    
        console.log("line 39")
        console.log(userVars)
        console.log(userVars.stripeCustId)
    
        
        const url_portal="/backend/stripe/create-portal-session"
        const response = await fetch(url_portal, {
           method: 'POST',
           headers: { 'Content-Type': 'application/json'},
            body: JSON.stringify(
                {
                    "user_id":userVars._id,
                    "customer_id":userVars.stripeCustId,
                }
            )
          
          });
    
        const session = await response.json();
        console.log("line 54")
        console.log(session)
        console.log(session.url)
        window.open(session.url)
    
        
      };
    
    if(userVars?.subscriptionStatus=='Basic'){
        return (
            <>
            <PricingHeader />
            <PricingCardCont />
            <Link to='/subscription'>
                SUBSCRIBE!
            </Link>
            </>
            );
    }
    else if(userVars==null) {
        return (
            <>
            <PricingHeader />
            <PricingCardCont />
            <Link to='/sign-up'>
                Register now!
            </Link>
            </>
            );
    } else{
        return (
            <>
            <PricingHeader />
            <PricingCardCont />
            <Button onClick={handleSubscriptionChange}>
                Manage Subscription
            </Button>
            </>
            );
    }

   
}