import React from 'react';
import { auth} from '../config/firebase'



const SubsButton = ({user,upgrade,beta,type}) => {
  const usid=user?._id
  const customer_id=user?.stripeCustId
  if (type=="Basic"){
    
  }
  else if(type=="Premium"){
    
  }
  else if (type=="Elite"){
    
  }
  else {
    console.log("ERROR LINE 20 SubsButton.js")
  }
  const handleClick = async () => {
    if(auth.currentUser?.uid != user?._id){
      return; 
    }}
    




if (upgrade){
    return (
        <button onClick={handleClick}>
          Change Plan
        </button>
      );
}
else if(beta){
    return (
        <button className="link-grey">
          TBD
        </button>
      );

}
else
{

    return (
        <button className="link-grey">
          Your Current Tier
        </button>
      );
}
};

export default SubsButton;
