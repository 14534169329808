import React from 'react'
import BlogPost from '../components/BlogPost'


function Blog(){


 
    return (
      <div className='blog-page'>
        <BlogPost />
      </div>
    );
  
}

export default Blog;
