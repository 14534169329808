import React from 'react';
import SubCard from './SubCard';

const SubCardCont = ({user}) => {
    const tiers = [
        {
            title: 'Basic',
            price: 'Free',
            features: ['Feature 1', 'Feature 2', 'Feature 3']
        },
        {
            title: 'Premium',
            price: '$9.99/mo',
            features: ['Feature 1', 'Feature 2', 'Feature 3', 'Feature 4', 'Feature 5']
        },
        {
            title: 'Elite',
            price: 'TBD',
            features: ['All Pro features', 'TBD']
        }
    ];

    return (
        <div>
            
            <div className="pricing-container">
                {tiers.map((tier, index) => (
                    <SubCard key={index} tier={tier} user={user} />
                ))}
            </div>
        </div>
    );
};

export default SubCardCont;
