import React, { useEffect, useState } from 'react'
import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';
import ReportsCards from './ReportsCards'
import { auth } from '../config/firebase';



function ReportsViewer({userid, reptype}) {


const [reports,setReports]=useState(null)
const [month,setMonth]=useState(null)
const [userVars,setUserVars]=useState(null)



const url1="/backend/api/reports/report-fetch-by-month-and-user/"
const url2="/backend/api/user/user-fetch/" + userid

useEffect(() => {

const fetchUserVars= async () => {
  const response =await fetch(url2,
    {method: 'GET',
    headers: { 'Content-Type': 'application/json',
    'Authorization':await auth?.currentUser?.getIdToken(),}}
    )
    console.log("url2")
    console.log(url2)

  const user_stuff= await response.json()
  if (response.ok){
    setUserVars(user_stuff)
    console.log("29")
    console.log(response)
    console.log(userVars)
  }
}


const fetchReports= async () => {
    const month_get=month
    const year_get=2023
    const access_level=reptype
    const premiumEnd=userVars?.premiumEndDate
    const eliteEnd=userVars?.eliteEndDate
    const response =await fetch(url1,
    {
        method: 'POST',
        headers: { 'Content-Type': 'application/json',
        'Authorization':await auth?.currentUser?.getIdToken(),},
        body: JSON.stringify(
            {
                "month": month_get,
                "year": year_get,
                "accesslevel":access_level,
                "premiumEndDate": premiumEnd,
                "eliteEndDate" : eliteEnd
            }
        )

    }
 )
const json= await response.json()
if (response.ok){
    setReports(json)
}

}
fetchUserVars()
fetchReports()
},[month])



  return (
    <>
    <div className='ReportsTable'>
    <DropdownButton id="dropdown-basic-button" title="Reports by Month">
      <Dropdown.Item  onClick={() => setMonth(5)}>May 2023</Dropdown.Item>
      <Dropdown.Item  onClick={() => setMonth(6)}>June 2023</Dropdown.Item>
    </DropdownButton>
    {/* {console.log(reports)} */}
    <ReportsCards reports={reports} />
       

    </div>
    
    </>
  )
}

export default ReportsViewer