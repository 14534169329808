import React from 'react';
import PricingCard from './PricingCard';

const PricingCardCont = () => {
    const tiers = [
        {
            title: 'Basic',
            price: 'Free',
            features: ['Reports that deal with all 31 of the top level categories', 'Summaries and data tables indicating category performance over time period', 'Access to all freebies']
        },
        {
            title: 'Pro',
            price: '$9.99/mo',
            features: ['All features from the Basic tier', 'Reports that deal with subcategories for the most popular top level fiction categories', 'More detailed monthly reports']
        },
        {
            title: 'Enterprise',
            price: 'Contact us',
            features: ['TBD']
        }
    ];

    return (
        <div>
            
            <div className="pricing-container">
                {tiers.map((tier, index) => (
                    <PricingCard key={index} tier={tier} />
                ))}
            </div>
        </div>
    );
};

export default PricingCardCont;
