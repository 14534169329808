import React from 'react'
import { Link } from 'react-router-dom'

function ReportsCards({reports}) {
  return (
    <div className='reports-list' >
            {reports && reports.map((rep) => (<div key={rep.title +" div"}>
                <Link key={rep.title} to={`/content/${rep.reportType}/${rep._id}`}>{`${rep.title} - ${rep.date}`}</Link>
                </div>
            )

            )  }

        </div>
  )
}

export default ReportsCards