import React from 'react';

const PricingCard = ({ tier }) => {
    return (
        <div className="pricing-card">
            <h2>{tier.title}</h2>
            <h3>{tier.price}</h3>
            <ul>
                {tier.features.map((feature, index) => (
                    <li key={index}>{feature}</li>
                ))}
            </ul>
        </div>
    );
};

export default PricingCard;
