import React from 'react';

const Privacy = () => {
  return (
    <div>
      <h1>Privacy Policy</h1>

      <h2>1. Introduction</h2>
      <p>We are committed to safeguarding the privacy of our website visitors and service users; in this policy, we explain how we will handle your personal data.</p>

      <h2>2. How we use your personal data</h2>
      <p>We may process data about your use of our website and services. The usage data may include your IP address, geographical location, browser type and version, operating system, referral source, length of visit, page views and website navigation paths, as well as information about the timing, frequency and pattern of your service use.</p>

      <h2>3. Providing your personal data to others</h2>
      <p>We may disclose your personal data to any member of our group of companies (this means our subsidiaries, our ultimate holding company and all its subsidiaries) insofar as reasonably necessary for the purposes set out in this policy.</p>

      <h2>4. International transfers of your personal data</h2>
      <p>In this Section, we provide information about the circumstances in which your personal data may be transferred to [countries outside the European Economic Area (EEA)].</p>

      <h2>5. Retaining and deleting personal data</h2>
      <p>Personal data that we process for any purpose or purposes shall not be kept for longer than is necessary for that purpose or those purposes.</p>

      <h2>6. Amendments</h2>
      <p>We may update this policy from time to time by publishing a new version on our website. You should check this page occasionally to ensure you are happy with any changes to this policy.</p>

      <h2>7. Your rights</h2>
      <p>In this Section, we have summarized the rights that you have under data protection law. Some of the rights are complex, and not all of the details have been included in our summaries. Accordingly, you should read the relevant laws and guidance from the regulatory authorities for a full explanation of these rights.</p>
      
      <h2>8. About cookies</h2>
      <p>A cookie is a file containing an identifier (a string of letters and numbers) that is sent by a web server to a web browser and is stored by the browser. The identifier is then sent back to the server each time the browser requests a page from the server.</p>

    </div>
  );
}

export default Privacy;
