import React from 'react';

const Tos = () => {
  return (
    <div>
      <h1>Terms of Service</h1>
      
      <h2>1. Introduction</h2>
      <p>Welcome to our website. If you continue to browse and use this website, you are agreeing to comply with and be bound by the following terms and conditions of use, which together with our privacy policy govern our relationship with you in relation to this website. If you disagree with any part of these terms and conditions, please do not use our website.</p>

      <h2>2. User obligations</h2>
      <p>You agree to use this website only for lawful purposes, and in a way that does not infringe the rights of, restrict or inhibit anyone else’s use and enjoyment of this website. Prohibited behavior includes harassing or causing distress or inconvenience to any other user, transmitting obscene or offensive content or disrupting the normal flow of dialogue within this website.</p>

      <h2>3. Intellectual property</h2>
      <p>Unless otherwise stated, we or our licensors own the intellectual property rights in the website and material on the website. You may view, download for caching purposes only, and print pages from the website for your own personal use, subject to the restrictions set out below and elsewhere in these terms of service.</p>

      <h2>4. Limitations and exclusions of liability</h2>
      <p>To the extent that the website and the information and services on the website are provided, we will not be liable for any loss or damage of any nature.</p>

      <h2>5. Variation</h2>
      <p>We may revise these terms of service from time-to-time. Revised terms of service will apply to the use of our website from the date of the publication of the revised terms of service on our website.</p>

      <h2>6. Entire agreement</h2>
      <p>These terms of service, together with our privacy policy, constitute the entire agreement between you and us in relation to your use of our website, and supersede all previous agreements in respect of your use of this website.</p>

      <h2>7. Law and jurisdiction</h2>
      <p>These terms of service will be governed by and construed in accordance with the laws of our jurisdiction, and any disputes relating to these terms of service will be subject to the exclusive jurisdiction of the courts of our jurisdiction.</p>
    </div>
  );
}

export default Tos;
