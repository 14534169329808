import React, { useState,useEffect } from 'react'
import { ReactMarkdown } from 'react-markdown/lib/react-markdown'

import { useParams } from 'react-router-dom';


function GetArticle() {
  let {id} = useParams();
  console.log(id)
  return id;
}

function BlogPost() {
    const [md,setMD]=useState('')
    let thing = GetArticle();
    
    useEffect(() => {
    const markdownfile= fetch(`/articles/article${thing}.md`).then(
       response => response.text()
    ).then(text => setMD(text))
}
    ,[])

    if (md == null){
      return(
        <>
        <div> Sorry it looks like something got messed up! </div>
        </>
      )
    }
  return (
    <div>
       <ReactMarkdown>
    
            {md}
       
       </ReactMarkdown>
    </div>
  )
}

export default BlogPost