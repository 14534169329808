import React from 'react'
import './Register.css'
import Register from './Register'
export default function SignUp(){
    return(
        <>
        <Register />
        </>

    );
}