import {useState} from 'react'
import { Link } from 'react-router-dom'
import './Register.css'
import {auth} from '../config/firebase'
import {createUserWithEmailAndPassword,sendEmailVerification} from 'firebase/auth'
import { useAuthValue } from '../components/AuthContext';
import {useNavigate} from 'react-router-dom'


function Register() {

  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [confirmPassword, setConfirmPassword] = useState('')
  const [error, setError] = useState('')

  const navigate=useNavigate()
  const {setTimeActive}=useAuthValue()

  

  const createUser= async ({usid,user_email,token}) => {
    const url="/backend/api/user/user-create/"
   

    try{
    const response = await fetch(url,
      {
        method: 'POST',
        headers: { 'Content-Type': 'application/json',
        'Authorization':token,},
        body: JSON.stringify(
            {
                "_id":usid,
                "email":user_email,
                "subscriptionStatus": "Basic",
                "subscriptionLevel": 0,
                "premiumEndDate": "12/12/1999",
                "eliteEndDate":"12/12/1999",
                "mailingList":true,
                "verified":false,
            }
        )

    }
      )
      const jsonresp=await response.json()
      console.log("user??")
      console.log(jsonresp)
  } catch (error){
    console.log('oops')
    console.log(error.message)
  }
    

  }

const validatePassword = () => {
  let isValid = true
  if (password !== '' && confirmPassword !== ''){
    if (password !== confirmPassword) {
      isValid = false
      setError('Passwords does not match')
    }
  }
  return isValid
}

const register = async e => {
  e.preventDefault()
  setError('')
  if(validatePassword()) {
    // Create a new user with email and password using firebase
    createUserWithEmailAndPassword(auth, email, password)
    .then(async () => {
      sendEmailVerification(auth.currentUser)   
      .then(async () => {
        setTimeActive(true)
        const usid=auth?.currentUser?.uid
        const user_email=auth?.currentUser?.email
        const token= await auth?.currentUser?.getIdToken()
  
        createUser({usid,user_email,token})
        navigate('/verify-email')
          
       

      }).catch((err) => alert(err.message))
    })
    .catch(err => setError(err.message))

   
  }
  setEmail('')
  setPassword('')
  setConfirmPassword('')
}

  return (
    <>
    <h1>Register</h1>
    <div className='container'>
      <div className='auth'>
        
        <br></br>
        {error && <div className='auth__error'>{error}</div>}
        <form onSubmit={register} name='registration_form'>
          <input 
            type='email' 
            value={email}
            placeholder="Enter your email"
            required
            onChange={e => setEmail(e.target.value)}/>

          <input 
            type='password'
            value={password} 
            required
            placeholder='Enter your password'
            onChange={e => setPassword(e.target.value)}/>

            <input 
            type='password'
            value={confirmPassword} 
            required
            placeholder='Confirm password'
            onChange={e => setConfirmPassword(e.target.value)}/>

          <button type='submit'>Register</button>
        </form>
      </div>
      
    </div>
    <div className='container'>
          Already have an account?  
          <Link to='/login'>login</Link>
    </div>
    </>
  )
}

export default Register