import React from 'react'

import { auth} from '../config/firebase'
import { useState,useEffect } from 'react'
import {redirect} from 'react-router-dom'
import SubCardCont from '../components/SubCardCont'



function Subscription() {

  const [userVars,setUserVars]=useState(null)
  const userid = auth?.currentUser?.uid

  

  useEffect(()=>
  {   
      const url2="/backend/api/user/user-fetch/" + userid
      const fetchUserVars= async () => {
      const response =await fetch(url2,
      {method: 'GET',
      headers: { 'Content-Type': 'application/json',
      'Authorization':await auth?.currentUser?.getIdToken(),}}
      )
  
      const user_stuff= await response.json()
      if (response.ok){
      setUserVars(user_stuff)
      console.log("29")
      console.log(response)
      console.log(userVars)
      }
  }
      fetchUserVars()
  }
      ,[])


  console.log("Subscription.js")
  console.log("Line 39 userVars")
  console.log(userVars)

 return (
<>
<SubCardCont user={userVars} />
</>

  )
}

export default Subscription