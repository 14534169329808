import React, { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import './LoginPopup.css'
import 'bootstrap/dist/css/bootstrap.min.css';

import {signInWithEmailAndPassword, sendEmailVerification} from 'firebase/auth';
import {auth} from '../config/firebase';
import {useNavigate} from 'react-router-dom';
import {useAuthValue} from './AuthContext';

function LoginPopup({state,stateHandler}) {
  const show=state;
  const setShow=stateHandler;
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('') 
  const [error, setError] = useState('')

  const handleClose = () => setShow(false);

  const {setTimeActive} = useAuthValue()
  const navigate=useNavigate()

  const login = e => {
  e.preventDefault()
  signInWithEmailAndPassword(auth, email, password)
  .then(() => {
    if(!auth.currentUser.emailVerified) {
      sendEmailVerification(auth.currentUser)
      .then(() => {
        setTimeActive(true)
        handleClose()
        navigate('/verify-email')
      })
    .catch(err => alert(err.message))
  }else{
    handleClose()
    navigate('/')
  }
  })
  .catch(err => setError(err.message))
}

  return (
    <>
        
      <Modal show={show} onHide={handleClose} className="mymodal2">
        <Modal.Header closeButton>
          <Modal.Title>Login</Modal.Title>
          {error && <div className='auth__error'>{error}</div>}
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={login}>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label>Email address</Form.Label>
              <Form.Control
                type="email"
                autoFocus
                value={email}
                required
                placeholder="Enter your email"
                onChange={e => setEmail(e.target.value)}/>
            </Form.Group>
            <Form.Group
              className="mb-3"
              controlId="exampleForm.ControlTextarea1"
            >
              <Form.Label>Password</Form.Label>
              <Form.Control type='password'
              required
              placeholder='Enter your password'
              onChange={e => setPassword(e.target.value)}/>
            </Form.Group>
            <Button variant="primary" type='submit'>
            Login
          </Button>
          <Button variant="secondary" onClick={handleClose}>
            Forgot password?
          </Button>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          
        </Modal.Footer>
      </Modal>
    </>
  );
}
export default LoginPopup