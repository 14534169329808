import React, {Suspense} from 'react'
import '../App.css'
import { useParams } from 'react-router-dom';

import { auth } from '../config/firebase';

const Article = React.lazy(() => import('./Article.js'))



function GetArticle() {
    let {id} = useParams();
    console.log(id)
    return id;
}


function ArticlePage() {
    let report_id = GetArticle();
    let user_id=auth?.currentUser?.uid
    return (
        <div>
          <Suspense fallback={<div>Loading</div>}>
          <Article
            report_id={report_id}
            user_id={user_id}
          />
          </Suspense>
        </div>
      );
    };

    export default ArticlePage;