import React, { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import './RegisterPopup.css'
import 'bootstrap/dist/css/bootstrap.min.css';
import {useNavigate} from 'react-router-dom'
import {createUserWithEmailAndPassword, sendEmailVerification} from 'firebase/auth'
import { auth} from '../config/firebase';
import { useAuthValue } from './AuthContext';

function RegisterPopup({state,stateHandler}) {



  const createUser= async ({usid,user_email,token}) => {
    const url="/backend/api/user/user-create/"
   

    try{
    const response = await fetch(url,
      {
        method: 'POST',
        headers: { 'Content-Type': 'application/json',
        'Authorization':token,},
        body: JSON.stringify(
            {
                "_id":usid,
                "email":user_email,
                "subscriptionStatus": "Premium",
                "subscriptionLevel": 1,
                "premiumEndDate": "12/12/2023",
                "eliteEndDate":"12/12/1999",
                "mailingList":true,
                "verified":false,
            }
        )

    }
      )
      const jsonresp=await response.json()
      console.log("user??")
      console.log(jsonresp)
  } catch (error){
    console.log('oops')
    console.log(error.message)
  }
    

  }


  const show=state;
  const setShow=stateHandler;

  const handleClose = () => setShow(false);
  

  
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [confirmPassword, setConfirmPassword] = useState('')
  const [error, setError] = useState('')

  const navigate=useNavigate()
  const {setTimeActive}=useAuthValue()

const validatePassword = () => {
  let isValid = true
  if (password !== '' && confirmPassword !== ''){
    if (password !== confirmPassword) {
      isValid = false
      setError('Passwords does not match')
    }
  }
  return isValid
}

const register = async e => {
  e.preventDefault()
  setError('')
  if(validatePassword()) {
    // Create a new user with email and password using firebase
    createUserWithEmailAndPassword(auth, email, password)
    .then(async () => {
      sendEmailVerification(auth.currentUser)   
      .then(async () => {
        setTimeActive(true)
        
        const usid=auth?.currentUser?.uid
        const user_email=auth?.currentUser?.email
        const token= await auth?.currentUser?.getIdToken()
        createUser({usid,user_email,token})
        navigate('/verify-email')
          
       

      }).catch((err) => alert(err.message))
    })
    .catch(err => setError(err.message))

   
  }
  setEmail('')
  setPassword('')
  setConfirmPassword('')
}

  return (
    <>
        
      <Modal show={show} onHide={handleClose} className="mymodal2">
        <Modal.Header closeButton>
          <Modal.Title>Register</Modal.Title>
          {error && <div className='auth__error'>{error}</div>}
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={register}>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label>Email address</Form.Label>
              <Form.Control
                type="email"
                value={email}
              placeholder="Enter your email"
              required
              onChange={e => setEmail(e.target.value)}/>
            </Form.Group>
            <Form.Group
              className="mb-3"
            >
              <Form.Label>Password</Form.Label>
              <Form.Control type='password'
              value={password} 
              required
              placeholder='Enter your password'
              onChange={e => setPassword(e.target.value)}/>
            </Form.Group>
            <Form.Group
              className="mb-3"
            >
              <Form.Label>Confirm password</Form.Label>
              <Form.Control type='password'
              value={confirmPassword} 
              required
              placeholder='Confirm password'
              onChange={e => setConfirmPassword(e.target.value)}/>
            </Form.Group>
            <Button type='submit' variant="primary" value="Submit" onClick={handleClose}>
            Register
          </Button>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          
        </Modal.Footer>
      </Modal>
    </>
  );
}
export default RegisterPopup