import React from 'react';
import SubsButton from './SubsButton';
import './SubCard.css';


const SubCard = ({ tier,user }) => {
    console.log("subcard")
    console.log(tier.title)
    console.log(user?.subscriptionStatus)
    if (tier.title == user?.subscriptionStatus){
        return (
            <div className="pricing-card-highlighted">
                <h2>{tier.title}</h2>
                <h3>{tier.price}</h3>
                <ul>
                    {tier.features.map((feature, index) => (
                        <li key={index}>{feature}</li>
                    ))}
                </ul>
            
            <div className='subs-button'>
                <>
                <SubsButton user={user} upgrade={false} type={tier.title}/>
                </>
            </div>
            </div>
        );
    }
    else if(tier.title=="Elite"){
        return (
            <div className="pricing-card-highlighted">
                <h2>{tier.title}</h2>
                <h3>{tier.price}</h3>
                <ul>
                    {tier.features.map((feature, index) => (
                        <li key={index}>{feature}</li>
                    ))}
                </ul>
            
            <div className='subs-button'>
                <>
                <SubsButton user={user} upgrade={false} beta={true} type={tier.title} />
                </>
            </div>
            </div>
        );
    }
    else{

    
    return (
        <div className="pricing-card">
            <h2>{tier.title}</h2>
            <h3>{tier.price}</h3>
            <ul>
                {tier.features.map((feature, index) => (
                    <li key={index}>{feature}</li>
                ))}
            </ul>
        
        <div className='subs-button'>
            <>
            <SubsButton user={user} upgrade={true} type={tier.title}/>
            </>
        </div>
        </div>
    );
}};

export default SubCard;
