import '../App.css'
import '../components/HeroSection.css'
import HeroSection from '../components/HeroSection.js'
import Cards from '../components/Cards'

function Home () {
    return (
        <>
        <HeroSection />
        <Cards />
        </>
    )

}

export default Home;